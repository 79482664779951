export const demoGenerators = [
  {
    id: "Chipmunk-Solar",
    name: "Chipmunk Solar",
  },
  {
    id: "Holiday-Creek-Solar",
    name: "Holiday Creek Solar",
  },
  {
    id: "Viking-Wind",
    name: "Viking Wind",
  },
  {
    id: "Nobles-Wind",
    name: "Nobles Wind",
  },
];
