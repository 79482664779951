export const demoDocuments = [
  {
    id: "20008533-ae41-4b0f-9bd1-06d252b75b9a",
    fileName: "test.png",
    mediaType: "image/png",
    type: "OTHER",
    size: 150275,
    __typename: "Document",
  },
  {
    id: "a4f89f87-8715-40dd-bad9-93a4b28406a1",
    fileName: "ea15523b-9c9f-49c3-bdcc-8a106bb8c50a.jpg",
    mediaType: "image/jpeg",
    type: "ID_CARD",
    size: 10573,
    __typename: "Document",
  },
  {
    id: "9fafe42b-92fc-4983-91e3-e9310a12e992",
    fileName: "ABC.png",
    mediaType: "image/png",
    type: "ID_CARD",
    size: 1161324,
    __typename: "Document",
  },
  {
    id: "731e4dc9-3efe-4374-a0c1-118b51b8db2d",
    fileName: "photo_2021-07-09_15-14-10.jpg",
    mediaType: "image/jpeg",
    type: "ID_CARD",
    size: 17826,
    __typename: "Document",
  },
];
