export const demoHoldings = [
  {
    id: "19da47e9-be5e-4f83-9609-62102144415f",
    availableBalance: 1043,
    currentBalance: 0,
    project: "Chipmunk-Solar",
    attributes: {
      __typename: "RecAssetAttributes",
      vintage: 2021,
      location: "OH",
      fuelSources: ["BSW"],
      project: "Chipmunk-Solar",
      eligibilities: ["sd"],
      certifications: ["CRS"],
      commencementDate: "2003-12-21",
      vintageHalf: "FRONT_HALF",
    },
  },
  {
    id: "d6c59493-a881-4a6e-87c5-9c4af3e37d52",
    availableBalance: 1935,
    currentBalance: 1935,
    project: "Nobles-Wind",
    attributes: {
      __typename: "RecAssetAttributes",
      vintage: 2021,
      location: "MN",
      fuelSources: ["H2O"],
      project: "Nobles-Wind",
      eligibilities: ["wi_non_bankable"],
      certifications: ["CRS"],
      commencementDate: "2003-12-21",
      vintageHalf: "BACK_HALF",
    },
  },
  {
    id: "80292cf0-0d38-4fee-b1b0-c8da5393f71f",
    availableBalance: 1093,
    currentBalance: 1093,
    project: "Holiday-Creek-Solar",
    attributes: {
      __typename: "RecAssetAttributes",
      vintage: 2022,
      location: "IA",
      fuelSources: ["MS1", "BA3"],
      project: "Holiday-Creek-Solar",
      eligibilities: ["wi_bankable"],
      certifications: ["CRS"],
      commencementDate: "2003-12-21",
      vintageHalf: "FRONT_HALF",
    },
  },
  {
    id: "f79e3e25-c35f-4676-a7e2-4988158c6000",
    availableBalance: 161,
    currentBalance: 0,
    project: "Chipmunk-Solar",
    attributes: {
      __typename: "RecAssetAttributes",
      vintage: 2021,
      location: "OH",
      fuelSources: ["CON"],
      project: "Chipmunk-Solar",
      eligibilities: ["il_utility"],
      certifications: ["CRS"],
      commencementDate: "2003-12-21",
      vintageHalf: "BACK_HALF",
    },
  },
  {
    id: "aa56b1cb-ab49-4e0a-bd81-37b9eea0d188",
    availableBalance: 283,
    currentBalance: 0,
    project: "Nobles-Wind",
    attributes: {
      __typename: "RecAssetAttributes",
      vintage: 2022,
      location: "MN",
      fuelSources: ["BA3", "WDR"],
      project: "Nobles-Wind",
      eligibilities: ["oh"],
      certifications: ["CRS"],
      commencementDate: "2003-12-21",
      vintageHalf: "BACK_HALF",
    },
  },
  {
    id: "89d274ae-77e1-4268-a625-121ba7b68ad3",
    availableBalance: 923,
    currentBalance: 0,
    project: "Chipmunk-Solar",
    attributes: {
      __typename: "RecAssetAttributes",
      vintage: 2020,
      location: "OH",
      fuelSources: ["BLQ"],
      project: "Chipmunk-Solar",
      eligibilities: ["ia_aep"],
      certifications: ["CRS"],
      commencementDate: "2003-12-21",
      vintageHalf: "FRONT_HALF",
    },
  },
  {
    id: "66aaac61-6650-4345-9188-78b3fbe5371f",
    availableBalance: 1397,
    currentBalance: 0,
    project: "Viking-Wind",
    attributes: {
      __typename: "RecAssetAttributes",
      vintage: 2022,
      location: "MN",
      fuelSources: ["BNV"],
      project: "Viking-Wind",
      eligibilities: ["il_utility"],
      certifications: ["CRS"],
      commencementDate: "2003-12-21",
      vintageHalf: "FRONT_HALF",
    },
  },
  {
    id: "687338ca-f50a-44e8-bd14-d7c97814fe06",
    availableBalance: 123,
    currentBalance: 123,
    project: "Chipmunk-Solar",
    attributes: {
      __typename: "RecAssetAttributes",
      vintage: 2021,
      location: "OH",
      fuelSources: ["HY2"],
      project: "Chipmunk-Solar",
      eligibilities: ["ia"],
      certifications: ["CRS"],
      commencementDate: "2003-12-21",
      vintageHalf: "BACK_HALF",
    },
  },
  {
    id: "47ec61d5-9734-45ad-b755-21cee243e24a",
    availableBalance: 1557,
    currentBalance: 0,
    project: "Holiday-Creek-Solar",
    attributes: {
      __typename: "RecAssetAttributes",
      vintage: 2021,
      location: "IA",
      fuelSources: ["PA1", "WBS"],
      project: "Holiday-Creek-Solar",
      eligibilities: ["wi_non_bankable"],
      certifications: ["CRS"],
      commencementDate: "2003-12-21",
      vintageHalf: "FRONT_HALF",
    },
  },
  {
    id: "212d0881-ee15-4b1e-a6d5-08f40aa06888",
    availableBalance: 1431,
    currentBalance: 756,
    project: "Chipmunk-Solar",
    attributes: {
      __typename: "RecAssetAttributes",
      vintage: 2022,
      location: "OH",
      fuelSources: ["HY2", "WHR"],
      project: "Chipmunk-Solar",
      eligibilities: ["ia_aep"],
      certifications: ["CRS"],
      commencementDate: "2003-12-21",
      vintageHalf: "FRONT_HALF",
    },
  },
];
